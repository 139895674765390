import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import Config from "@/common/config";
let cancelErrorMsg = "Operation canceled due to new request.";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = Config.get("api_url");
    Vue.axios.defaults.timeout = Config.get("axios_timeout");
    console.warn(`version: ${Config.get("version")}`);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  },

  getBase() {
    return Config.get("api_url");
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = {}) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, config = {}) {
    return Vue.axios.put(`${resource}/${slug}`, params, config);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, config = {}) {
    return Vue.axios.put(`${resource}`, params, config);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, config = {}) {
    return Vue.axios.delete(resource, config).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  getCancelToken() {
    return Vue.axios.CancelToken.source();
  },

  getCancelErrorMessage() {
    return cancelErrorMsg;
  }
};

export default ApiService;
