import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/common/config/i18n/en";
import { locale as ch } from "@/common/config/i18n/ch";
import { locale as es } from "@/common/config/i18n/es";
import { locale as jp } from "@/common/config/i18n/jp";
import { locale as de } from "@/common/config/i18n/de";
import { locale as fr } from "@/common/config/i18n/fr";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, ch, es, jp, de, fr };

const dateTimeFormats = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "fr-FR": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric"
    }
  }
};

// get current selected language
export const lang = "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  dateTimeFormats
});

export default i18n;
