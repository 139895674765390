import {
  _get,
  _post,
  _postWithCancel,
  _delete,
  _update,
  _postFile,
  _postWithCompleteResponse,
  _getWithCompleteResponse
} from "./http-request";
import { BASE_URL, BASE_PATH } from "./config/api2.0";
import { generateBuildUrl } from "./url";
import config from "@/common/config";
import JwtService from "@/common/jwt.service";

("use strict");
const URL_API = config.items.api_url_v2;

const buildUrl = generateBuildUrl(BASE_URL + BASE_PATH);

export async function get(path, params = {}, axios) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _get(path, params, axios, buildUrl);
}

export async function getWithCompleteResponse(path, params = {}, axios) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _getWithCompleteResponse(path, params, axios, buildUrl);
}

export async function post(path, params = {}, data, axios, timeout = 30000) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _post(path, params, data, axios, buildUrl, timeout);
}

export async function postWithoutToken(
  path,
  params = {},
  data,
  axios,
  timeout = 30000
) {
  axios.defaults.baseURL = URL_API;
  if (axios.defaults.headers.common.hasOwnProperty("Authorization")) {
    delete axios.defaults.headers.common["Authorization"];
  }
  return _post(path, params, data, axios, buildUrl, timeout);
}

export async function postWithCancel(
  path,
  params = {},
  data,
  axios,
  options = { timeout: 30000, cancelToken: null }
) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _postWithCancel(path, params, data, axios, buildUrl, options);
}

export async function postWithCompleteResponse(
  path,
  params = {},
  data,
  axios,
  timeout = 30000
) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _postWithCompleteResponse(
    path,
    params,
    data,
    axios,
    buildUrl,
    timeout
  );
}

export async function postFile(path, params = {}, data, axios) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _postFile(path, params, data, axios, buildUrl);
}

export async function update(path, data, axios, params = {}) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _update(path, data, axios, buildUrl, params);
}

export const remove = async (path, data, axios) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
  axios.defaults.baseURL = URL_API;
  return _delete(path, data, axios, buildUrl);
};
