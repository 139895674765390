import { managerErrorsApi } from "./manageErrors";

("use strict");

const _get = async (path, params, axios, urlBuilder) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url, params);
  }
};

const _getWithCompleteResponse = async (path, params, axios, urlBuilder) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.get(url, { params });
    return response;
  } catch (error) {
    throw managerErrorsApi(error, url, params);
  }
};

const _post = async (path, params = {}, data, axios, urlBuilder, timeout) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.post(
      url,
      data,
      { timeout: timeout },
      { params }
    );
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

const _postWithCancel = async (
  path,
  params = {},
  data,
  axios,
  urlBuilder,
  options
) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.post(url, data, options, { params });
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

const _postWithCompleteResponse = async (
  path,
  params = {},
  data,
  axios,
  urlBuilder,
  timeout
) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.post(
      url,
      data,
      { timeout: timeout },
      { params }
    );
    return response;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

const _postFile = async (path, params = {}, data, axios, urlBuilder) => {
  const url = urlBuilder(path);

  try {
    const response = await axios.post(
      url,
      data,
      { params },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

const _update = async (path, data, axios, urlBuilder, params = {}) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.put(url, data, { params });
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

// the keyword delete is already reserved, thus we use underscore
const _delete = async (path, data, axios, urlBuilder) => {
  const url = urlBuilder(path);
  try {
    const response = await axios.delete(url, { data });
    return response.data;
  } catch (error) {
    throw managerErrorsApi(error, url);
  }
};

// Better to do this but it would require that we provide services inside the vue tree, (DI pattern)
const createGetFromAxios = axios => {
  return (path, params) => _get(path, params, axios);
};
export {
  _get,
  _getWithCompleteResponse,
  _post,
  _postWithCompleteResponse,
  _update,
  _delete,
  createGetFromAxios,
  _postFile,
  _postWithCancel
};
