export function managerErrorsApi(error, url, params) {
  console.error("---------- ERROR CATCH ---------");
  console.error(`URL : ${url}`);
  if (error.response) {
    console.error(
      `Response status not 200 Server respond with ${error.response.status}`
    );
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("---- RESPONSE ----");
    console.error(error.response.data);
    console.error(error.response.headers);
    console.error("----- END RESPONSE -----");
  } else if (error.request) {
    console.error("No response received");
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    console.error("Unknown error");
    // Something happened in setting up the request that triggered an Error
    console.error("Error", error.message);
  }
  console.error("----- Request -----");
  console.error(url);
  console.error(params);
  console.error("------- END ERROR CATCH ------");
  return error;
}
