import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import { VERIFY_AUTH } from "./store/auth.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "./common/plugins/inline-svg";
import "./common/plugins/ctkDateTimePicker";
import "./common/plugins/JsonCSV";
import "./common/plugins/clickOutside";
import "./common/plugins/apex-charts";
import "./common/plugins/CKEditor";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if (!to.meta.roles.includes("guest")) {
    Promise.all([store.dispatch(VERIFY_AUTH)])
      .then(next)
      .catch(e => {
        if (e === true) {
          next({ name: "login" });
        } else {
          next({ name: "login", query: { auth: "expired" } });
        }
      });
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.filter("firstLetter", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
