const Config = {
  items: {
    version: process.env.VUE_APP_APP_VERSION
      ? process.env.VUE_APP_APP_VERSION
      : "0.0.0",
    api_url: process.env.VUE_APP_API_URL
      ? process.env.VUE_APP_API_URL
      : "http://127.0.0.1:8085/api",
    platform_listening: process.env.VUE_APP_PLATFORM_LISTENING
      ? process.env.VUE_APP_PLATFORM_LISTENING
      : "https://podcasts.voxeus.com/",
    contact_mail: process.env.VUE_APP_CONTACT_MAIL
      ? process.env.VUE_APP_CONTACT_MAIL
      : "contact@voxeus.com",
    api_url_v2: process.env.VUE_APP_API_URL_V2
      ? process.env.VUE_APP_API_URL_V2
      : "http://127.0.0.1:8087/api",
    api_discovery: process.env.VUE_APP_DISCOVERY_API_ENDPOINT
      ? process.env.VUE_APP_DISCOVERY_API_ENDPOINT
      : "http://discovery-local.voxeus.com:8088/",
    hosting_platform: process.env.VUE_APP_HOSTING_PLATFORM_ENDPOINT
      ? process.env.VUE_APP_HOSTING_PLATFORM_ENDPOINT
      : "http://hosting-local.voxeus.com:8089/",
    axios_timeout: process.env.VUE_APP_TIMEOUT_AXIOS
      ? process.env.VUE_APP_TIMEOUT_AXIOS
      : 30000,
    time_out_search: 500
  },

  get(item) {
    return this.items[item];
  }
};
export default Config;
