export const filterTagsByType = data => {
  const output = {
    tags: [],
    languages: []
  };

  if (data && data.length > 0) {
    //TODO, when tags in redis will be synchronised with tags in APIV2, clean this code by deletting the test "typeof.."
    // just let el.tagType.name !== "lang_type"
    const tags = data.filter(el => {
      if (typeof el.tagType === "object") {
        return el.tagType.name !== "lang_type";
      } else {
        return el.tagType !== "lang_type";
      }
    });
    const languages = data.filter(el => {
      if (typeof el.tagType === "object") {
        return el.tagType.name === "lang_type";
      } else {
        return el.tagType === "lang_type";
      }
    });

    output.tags = tags;
    output.languages = languages;
  }

  return output;
};
