import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import axios from "axios";
import {
  getUserSession,
  loginCheck,
  passwordForgot,
  passwordReset,
  userProfile
} from "@/api/users/api-v2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
// export const UPDATE_USER = "updateUser";
export const UPDATE_USER_PROFILE = "updateUserProfile";
export const PASSWORD_FORGOT = "password-forgot";
export const PASSWORD_RESET = "password-reset";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const UPDATE_USER = "updateUser";
export const SET_ERROR = "setError";

const state = {
  role: "guest",
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },

  isAuthenticated(state) {
    return state.isAuthenticated;
  },

  getRole(state) {
    return state.role;
  },

  getCompanyRoles(state) {
    return state.user.company?.categories;
  },

  isAdmin() {
    return state.role === "admin";
  },

  isPublisher() {
    return state.company?.categories.includes("Publisher");
  },

  isPaidCustomer() {
    return state.role === "customer_paid";
  },

  isPremium() {
    return (
      (state.role === "admin" || state.role === "customer_paid") &&
      state.user.company !== null
    );
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      loginCheck(axios, credentials)
        .then(data => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        getUserSession(axios)
          .then(data => {
            const localVersion = localStorage.getItem("fbo-v");
            const appVersion = "version" in data ? data.version : null;
            if (!localVersion) {
              localStorage.setItem("fbo-v", appVersion);
            } else if (localVersion && localVersion !== appVersion) {
              localStorage.setItem("fbo-v", data.version);
              // dirty fix for async reload after conn
              let timeout = 200;
              if (/^#\/login$/.test(location.hash)) {
                timeout = 4000;
              }
              setTimeout(() => {
                location.reload();
              }, timeout);
            }
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(response => {
            context.commit(PURGE_AUTH);
            reject(response);
          });
      } else {
        context.commit(PURGE_AUTH);
        reject(true);
      }
    });
  },
  [UPDATE_USER_PROFILE](context, payload) {
    return userProfile(axios, payload.user_id, payload).then(response => {
      const result = response.data ? response.data : {};

      context.commit(UPDATE_USER, result);
      return result;
    });
  },
  [PASSWORD_FORGOT](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise((resolve, reject) => {
      passwordForgot(axios, credentials)
        .then(data => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(
            SET_ERROR,
            response ? response.data.errors : "network error"
          );
          reject(response);
        });
    });
  },
  [PASSWORD_RESET](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise((resolve, reject) => {
      passwordReset(axios, credentials)
        .then(data => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(
            SET_ERROR,
            response ? response.data.errors : "network error"
          );
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors.push(error);
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.role = data.role;
    state.errors = [];
    if (data.token !== null) {
      JwtService.saveToken(data.token);
    }
  },
  [UPDATE_USER](state, data) {
    state.user = { ...state.user, ...data };
  },
  [PURGE_AUTH](state) {
    ApiService.setHeader();
    sessionStorage.clear();
    state.isAuthenticated = false;
    state.user = {};
    state.role = "guest";
    state.user.roles = [];
    state.errors = [];
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
