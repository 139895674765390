// mutation types
export const SET_FILTERS_OPTIONS = "setFiltersOptions";
export const SET_FILTERS_OPTIONS_ITEMS = "setFilterOptionsItems";
export const SET_FILTERS_OPTIONS_LOADING = "setFiltersOptionsLoading";
export const SET_FILTERS_OPTIONS_ERRORS = "setFiltersError";

export default {
  state: {
    filtersOptions: {
      campaigns: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false,
        params: {
          limit: 50,
          offset: 0,
          order: "id",
          direction: 1,
          q: ""
        }
      },
      programs: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      domains: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      episodes: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      devices: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      browsers: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      page_type: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      modes: {
        loading: false,
        items: [
          { value: "classic", name: "AudioHub" },
          { value: "embed", name: "Embed" },
          { value: "discovery", name: "Discovery" }
        ],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      utm_campaign: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      utm_source: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      utm_medium: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      },
      utm_content: {
        loading: false,
        items: [],
        selected: [],
        isElasticSearchFilter: true,
        storeValue: [],
        isError: false
      }
    }
  },
  getters: {
    /**
     * Get the filter options
     * @param state
     * @returns {Object}
     */
    filtersOptions(state) {
      return state.filtersOptions;
    }
  },
  actions: {
    /**
     * Set the filters options
     * @param state
     * @param {payload, field}
     */
    [SET_FILTERS_OPTIONS](state, { payload, field }) {
      state.commit(SET_FILTERS_OPTIONS, { payload, field });
    },

    [SET_FILTERS_OPTIONS_ITEMS](state, { field, payload }) {
      state.commit(SET_FILTERS_OPTIONS_ITEMS, { field, payload });
    },

    [SET_FILTERS_OPTIONS_LOADING](state, { isLoading, field }) {
      state.commit(SET_FILTERS_OPTIONS_LOADING, { isLoading, field });
    },

    [SET_FILTERS_OPTIONS_ERRORS](state, { field, payload }) {
      state.commit(SET_FILTERS_OPTIONS_ERRORS, { field, payload });
    }
  },
  mutations: {
    [SET_FILTERS_OPTIONS](state, { payload, field }) {
      state.filtersOptions[field].selected = payload;
    },

    [SET_FILTERS_OPTIONS_LOADING](state, { isLoading, field }) {
      state.filtersOptions[field].loading = isLoading;
    },

    [SET_FILTERS_OPTIONS_ITEMS](state, { field, payload }) {
      state.filtersOptions[field].items = payload;
    },

    [SET_FILTERS_OPTIONS_ERRORS](state, { field, payload }) {
      state.filtersOptions[field].isError = payload;
    }
  }
};
