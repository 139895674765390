export const ROLES = {
  BBB_ADMIN: "ROLE_BBB_ADMIN",
  BBB_MANAGER_PUBLISHER: "ROLE_BBB_MANAGER_PUBLISHER",
  BBB_MANAGER_ADVERTISER: "ROLE_BBB_MANAGER_ADVERTISER",
  PUBLISHER_ADMIN: "ROLE_PUBLISHER_ADMIN",
  PUBLISHER_USER: "ROLE_PUBLISHER_USER",
  ADVERTISER_ADMIN: "ROLE_ADVERTISER_ADMIN",
  ADVERTISER_USER: "ROLE_ADVERTISER_USER",
  HOSTING_MANAGER: "ROLE_HOSTING_MANAGER",
  HOSTING_USER: "ROLE_HOSTING_USER"
};

export const READABLE_ROLES_LIST = {
  [ROLES.BBB_ADMIN]: "Voxeus Admin",
  [ROLES.BBB_MANAGER_PUBLISHER]: "Voxeus Manager Publisher",
  [ROLES.BBB_MANAGER_ADVERTISER]: "Voxeus Manager Advertiser",
  [ROLES.PUBLISHER_ADMIN]: "Publisher Manager",
  [ROLES.PUBLISHER_USER]: "Publisher User",
  [ROLES.ADVERTISER_ADMIN]: "Advertiser Manager",
  [ROLES.ADVERTISER_USER]: "Advertiser User",
  [ROLES.HOSTING_MANAGER]: "Hosting Manager",
  [ROLES.HOSTING_USER]: "Hosting User"
};

export const getListRoles = user => {
  let hostingAccess = user.company.hostingAccess;

  if (user.roles.includes(ROLES.BBB_ADMIN)) {
    hostingAccess = true;
  }

  const data = [
    {
      text: READABLE_ROLES_LIST[ROLES.BBB_ADMIN],
      value: [ROLES.BBB_ADMIN],
      access: [ROLES.BBB_ADMIN]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.BBB_MANAGER_PUBLISHER],
      value: [ROLES.BBB_MANAGER_PUBLISHER],
      access: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.PUBLISHER_ADMIN],
      value: [ROLES.PUBLISHER_ADMIN],
      access: [
        ROLES.BBB_ADMIN,
        ROLES.BBB_MANAGER_PUBLISHER,
        ROLES.PUBLISHER_ADMIN
      ]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.PUBLISHER_USER],
      value: [ROLES.PUBLISHER_USER],
      access: [
        ROLES.BBB_ADMIN,
        ROLES.BBB_MANAGER_PUBLISHER,
        ROLES.PUBLISHER_ADMIN,
        ROLES.PUBLISHER_USER
      ]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.BBB_MANAGER_ADVERTISER],
      value: [ROLES.BBB_MANAGER_ADVERTISER],
      access: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.ADVERTISER_ADMIN],
      value: [ROLES.ADVERTISER_ADMIN],
      access: [
        ROLES.BBB_ADMIN,
        ROLES.BBB_MANAGER_ADVERTISER,
        ROLES.ADVERTISER_ADMIN
      ]
    },
    {
      text: READABLE_ROLES_LIST[ROLES.ADVERTISER_USER],
      value: [ROLES.ADVERTISER_USER],
      access: [
        ROLES.BBB_ADMIN,
        ROLES.BBB_MANAGER_ADVERTISER,
        ROLES.ADVERTISER_ADMIN,
        ROLES.ADVERTISER_USER
      ]
    }
  ];

  if (hostingAccess) {
    data.push(
      {
        text: READABLE_ROLES_LIST[ROLES.HOSTING_MANAGER],
        value: [ROLES.HOSTING_MANAGER],
        access: [ROLES.BBB_ADMIN, ROLES.HOSTING_MANAGER]
      },
      {
        text: READABLE_ROLES_LIST[ROLES.HOSTING_USER],
        value: [ROLES.HOSTING_USER],
        access: [ROLES.BBB_ADMIN, ROLES.HOSTING_MANAGER]
      }
    );
  }
  return data;
};
