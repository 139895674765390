export function generateBuildUrl(base) {
  return function(path) {
    const end = path.path;

    if (typeof end === typeof undefined) {
      throw new Error("Path is undefined");
    }
    if (path.prefix) {
      base = path.prefix;
    }
    var totalPath = base + end;

    if (!path.ressources || typeof path.ressources !== typeof []) {
      return totalPath;
    }

    if (!totalPath.endsWith("/")) {
      totalPath += "/";
    }

    for (let i = 0; i < path.ressources.length; i++) {
      const { name, value } = path.ressources[i];
      if (!name) {
        throw new Error("Ressources format is {name, value}");
      }
      totalPath += `${name}`;
      if (value) {
        totalPath += `/${value}`;
      }
      if (i !== path.ressources.length - 1) {
        totalPath += "/";
      }
    }
    return totalPath;
  };
}
