// mutation types
import ApiService from "@/common/api.service";
import axios from "axios";
import { createTag, deleteTag, getTags, updateTag } from "@/api/categories";
import { filterTagsByType } from "@/common/entities.service";

export const SET_CAMPAIGNS = "setCampaigns";
export const SET_COMPANIES = "setCompanies";
export const GET_COMPANIES = "getCompanies";
export const SET_COUNTRIES = "setCountries";
export const SET_PODCASTS = "setPodcasts";
export const GET_TAGS = "getTags";
export const SET_TAGS = "setTags";
export const SET_TAG = "setTag";
export const POST_TAG = "postTag";
export const PUT_TAG = "putTag";
export const DELETE_TAG = "deleteTag";
export const SET_LANGUAGES = "setLanguages";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_LOADING = "setLoading";

export default {
  namespaced: true,
  state: {
    errors: [],
    messages: [],
    loading: false,
    campaigns: [],
    companies: [],
    countries: [],
    podcasts: [],
    tags: [],
    languages: []
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    messages(state) {
      return state.messages;
    },
    loading(state) {
      return state.loading;
    },
    campaigns(state) {
      return state.campaigns;
    },
    campagnies(state) {
      return state.companies;
    },
    countries(state) {
      return state.countries;
    },
    podcasts(state) {
      return state.podcasts;
    },
    tags(state) {
      return state.tags;
    },
    languages(state) {
      return state.languages;
    },
    languagesCodes(state) {
      return state.languagesCodes;
    }
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_CAMPAIGNS](state, payload) {
      state.commit(SET_LOADING, true);
      state.commit(SET_CAMPAIGNS, payload);
      state.commit(SET_LOADING, false);
    },
    [GET_COMPANIES](state) {
      state.commit(SET_LOADING, true);
      ApiService.query(`/v1/companies`, {
        timeout: 25000
      })
        .then(({ data }) => {
          state.commit(SET_COMPANIES, data.rows);
        })
        .catch(({ response }) => {
          state.commit(
            SET_ERROR,
            response ? response.data.errors : "network error"
          );
        });
      state.commit(SET_LOADING, false);
    },
    [SET_COUNTRIES](state) {
      state.commit(SET_LOADING, true);
      ApiService.query(`/v1/countries`, {
        timeout: 25000
      })
        .then(({ data }) => {
          state.commit(SET_COUNTRIES, data.rows);
        })
        .catch(({ response }) => {
          state.commit(
            SET_ERROR,
            response ? response.data.errors : "network error"
          );
        });
      state.commit(SET_LOADING, false);
    },
    [SET_PODCASTS](state) {
      state.commit(SET_LOADING, true);
      ApiService.query(`/v1/programs?type=premium`, {
        timeout: 50000
      })
        .then(({ data }) => {
          state.commit(SET_PODCASTS, data.rows);
        })
        .catch(({ response }) => {
          state.commit(
            SET_ERROR,
            response ? response.data.errors : "network error"
          );
        });
      state.commit(SET_LOADING, false);
    },
    async [GET_TAGS](state, isFiltered = false) {
      state.commit(SET_LOADING, true);
      const data = await getTags(axios);
      if (isFiltered) {
        const { tags, languages } = filterTagsByType(data);
        state.commit(SET_TAGS, tags);
        state.commit(SET_LANGUAGES, languages);
      } else {
        state.commit(SET_TAGS, data);
      }
      state.commit(SET_LOADING, false);
    },
    async [POST_TAG](state, tag) {
      state.commit(SET_LOADING, true);
      const data = await createTag(axios, tag);
      state.commit(SET_TAG, data);
      state.commit(SET_LOADING, false);
    },
    async [PUT_TAG](state, tag) {
      state.commit(SET_LOADING, true);
      const data = await updateTag(axios, tag);
      state.commit(PUT_TAG, data);
      state.commit(SET_LOADING, false);
    },
    async [DELETE_TAG](state, tag) {
      state.commit(SET_LOADING, true);
      const data = await deleteTag(axios, tag);
      state.commit(SET_MESSAGE, [data]);
      state.commit(SET_LOADING, false);
    },
    [SET_LOADING](state, isLoading) {
      state.commit(SET_PODCASTS, isLoading);
    },
    [SET_MESSAGE](state, messages) {
      state.commit(SET_MESSAGE, messages);
    }
  },
  mutations: {
    [SET_ERROR](state, error) {
      state.errors.push(error);
    },
    [SET_MESSAGE](state, messages) {
      state.messages = messages;
    },
    [SET_LOADING](state, isLoading) {
      state.loading = isLoading;
    },
    [SET_CAMPAIGNS](state, payload) {
      state.campaigns = payload;
    },
    [SET_COMPANIES](state, payload) {
      state.companies = payload;
    },
    [SET_COUNTRIES](state, payload) {
      state.countries = payload;
    },
    [SET_PODCASTS](state, payload) {
      state.podcasts = payload;
    },
    [SET_TAGS](state, payload) {
      state.tags = payload;
    },
    [SET_TAG](state, payload) {
      state.tags = [...state.tags, payload];
    },
    [PUT_TAG](state, payload) {
      state.tags = state.tags.map(el => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
    },
    [DELETE_TAG](state, payload) {
      state.tags = state.tags.filter(el => el.id !== payload.id);
    },
    [SET_LANGUAGES](state, payload) {
      state.languages = payload;
    }
  }
};
