import { _get, _post, _postWithCancel, _delete, _update } from "./http-request";
import { BASE_URL, BASE_PATH } from "./config/api";
import { generateBuildUrl } from "./url";
import config from "@/common/config";
import JwtService from "@/common/jwt.service";

("use strict");
const URL_API = config.items.api_url;

const buildUrl = generateBuildUrl(BASE_URL + BASE_PATH);

export async function get(path, params = {}, axios, connected = true) {
  if (connected) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  axios.defaults.baseURL = URL_API;
  return _get(path, params, axios, buildUrl);
}

export async function post(path, params = {}, data, axios, connected = true) {
  if (connected) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  axios.defaults.baseURL = URL_API;
  return _post(path, params, data, axios, buildUrl);
}

export async function update(path, data, axios, connected = true) {
  if (connected) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  axios.defaults.baseURL = URL_API;
  return _update(path, data, axios, buildUrl);
}

export const remove = async (path, data, axios, connected = true) => {
  if (connected) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  axios.defaults.baseURL = URL_API;
  return _delete(path, data, axios, buildUrl);
};

export async function postWithCancel(
  path,
  params = {},
  data,
  axios,
  options = { timeout: 30000, cancelToken: null },
  connected = true
) {
  if (connected) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  axios.defaults.baseURL = URL_API;
  return _postWithCancel(path, params, data, axios, buildUrl, options);
}
