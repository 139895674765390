import { ENTITY_NAME_IN_URI } from "../config/api2.0";
import { get, post, postWithoutToken, update } from "../getApi2.0";

export const loginCheck = (axios, data) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.LOGIN.path
    },
    {},
    data,
    axios
  );

export const getUserSession = axios =>
  get(
    {
      path: ENTITY_NAME_IN_URI.SESSION.path
    },
    {},
    axios
  );

export const passwordForgot = (axios, data) =>
  postWithoutToken(
    {
      path: ENTITY_NAME_IN_URI.SESSION.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.SESSION.forgot_password
        }
      ]
    },
    {},
    data,
    axios
  );

export const passwordReset = (axios, data) =>
  postWithoutToken(
    {
      path: ENTITY_NAME_IN_URI.SESSION.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.SESSION.reset_password
        }
      ]
    },
    {},
    data,
    axios
  );

export const userProfile = (axios, id, payload) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.USERS.path,
      ressources: [
        {
          name: id,
          value: ENTITY_NAME_IN_URI.USERS.profile
        }
      ]
    },
    payload,
    axios
  );
