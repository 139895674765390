import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#595d6e",
        secondary: "#e8ecfa",
        accent: "#D40F4F",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        base_pi: "#1C195C",
        voxeus_loader: "#FF961D"
      }
    }
  }
});
