// mutation types
export const SET_FILTERS = "setFilters";

export const SET_FILTERS_FIELD = "setFiltersField";

export default {
  state: {
    filters: {
      domains: [],
      programs: [],
      campaigns: [],
      utm_campaign: [],
      utm_source: [],
      utm_medium: [],
      utm_content: [],
      modes: [],
      devices: [],
      browsers: [],
      page_type: [],
      episodes: [],
      dateRange: []
    }
  },
  getters: {
    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    filters(state) {
      return state.filters;
    },
    dateRange(state) {
      return state.filters.dateRange;
    },
    Programs(state) {
      return state.filters.programs;
    }
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_FILTERS](state, payload) {
      state.commit(SET_FILTERS, payload);
    },

    [SET_FILTERS_FIELD](state, { payload, field }) {
      state.commit(SET_FILTERS_FIELD, { payload, field });
    }
  },
  mutations: {
    [SET_FILTERS](state, payload) {
      state.filters = { ...state.filters, ...payload };
    },

    [SET_FILTERS_FIELD](state, { payload, field }) {
      state.filters[field] = payload;
    }
  }
};
