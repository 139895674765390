import { post, update, remove } from "@/api/getApi1.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";
import {
  get as getV2,
  post as postV2,
  update as updateV2,
  remove as removeV2
} from "@/api/getApi2.0";

// Tags are Categories
export const getTags = (axios, filters = null) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CONFIGURATIONS.CATEGORIES.path
    },
    filters,
    axios
  );

export const getTagsType = (axios, tagsType) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.TAGS.path
    },
    {
      tag_type: tagsType
    },
    axios
  );

// Tags are Categories
export const createTag = (axios, data) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.CONFIGURATIONS.CATEGORY.path
    },
    {},
    data,
    axios
  );

export const createTagV2 = (axios, companyId, tagId, tagTypeId, data) =>
  postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.COMPANIES.path,
      ressources: [
        {
          name: companyId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.TAGS.path,
          value: tagId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.TAG_TYPE.path,
          value: tagTypeId
        }
      ]
    },
    {},
    data,
    axios
  );

// Tags are Categories
export const updateTag = (axios, data) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.CONFIGURATIONS.CATEGORY.path
    },
    data,
    axios
  );

export const updateTagV2 = (axios, companyId, tagId, tagTypeId, data) =>
  updateV2(
    {
      path: ENTITY_NAME_IN_URI_V2.COMPANIES.path,
      ressources: [
        {
          name: companyId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.TAGS.path,
          value: tagId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.TAG_TYPE.path,
          value: tagTypeId
        }
      ]
    },
    data,
    axios
  );

export const deleteTag = (axios, data) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI.CONFIGURATIONS.CATEGORY.path
    },
    data,
    axios
  );

export const deleteTagV2 = (axios, id) =>
  removeV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CONFIGURATIONS.CATEGORIES.path,
      ressources: [
        {
          name: id
        }
      ]
    },
    {},
    axios
  );

export const getTagTypes = (axios, filters = null) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CONFIGURATIONS.CATEGORIES_TYPES.path
    },
    filters,
    axios
  );
