export const BASE_URL = ""; // The base path

export const BASE_PATH = "/v1/"; // The base path

export const TOKEN_API_PARAM_NAME = "auth";

export const ADVERTISER_TYPE = "advertiser";

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
};

export const ENTITY_NAME_IN_URI = {
  STATS: {
    path: "stats/dashboard",
    AUDIENCE: {
      path: "stats/top",
      name: "plays",
      value: "domains",
      type: "programs",
      filter: {
        limit: 10,
        order_by: "player.play"
      }
    },
    REFERERS: {
      path: "stats/top",
      name: "referrers",
      filter: {
        limit: 20,
        order_by: "player.play"
      }
    },
    INVENTORY: {
      path: "stats/top",
      name: "inventories",
      value: "domains",
      type: "programs",
      filter: {
        limit: 10,
        order_by: "sponsoring.inventory"
      }
    },
    TOTAL: {
      path: "stats/global"
    },
    REVENUE: {
      GLOBAL: {
        path: "stats/dashboard/revenue/",
        name: "global",
        filter: {
          metrics: 1
        }
      },
      DAILY: {
        path: "stats/dashboard/revenue/",
        name: "daily",
        filter: {
          metrics: 1
        }
      },
      TABLE: {
        path: "stats/dashboard/revenue/",
        name: "table",
        filter: {
          metrics: 1
        }
      }
    },
    DASHBOARD: {
      path: "stats",
      name: "dashboard"
    },
    CAMPAIGNS: {
      path: "stats/dashboard",
      name: "heatmap"
    },
    SPONSORING: {
      path: "stats",
      name: "dashboard",
      value: "sponsoring/group"
    },
    GEOLOC_DATA: {
      path: "stats/dashboard",
      name: "geolocation",
      value: "global"
    },
    CONVERSION: {
      path: "stats/dashboard",
      name: "conversion"
    }
  },
  CAMPAIGNS: {
    path: "sponsorings",
    DELIVERY: {
      path: "campaigns/dashboard",
      name: "delivery"
    },
    BROADCAST: {
      path: "campaigns/dashboard",
      name: "broadcast"
    }
  },
  CLAIM: {
    SIGNUP: {
      CUSTOMER: {
        path: "session",
        name: "register/customer"
      },
      PREMIUM: {
        path: "session",
        name: "register/premium"
      },
      APPROVAL: {
        path: "session",
        name: "register/approval"
      }
    },
    VALIDATION: {
      CUSTOMER: {
        path: "session",
        name: "validation/customer"
      },
      PREMIUM: {
        path: "session",
        name: "validation/premium"
      },
      APPROVAL: {
        path: "session",
        name: "validation/approval"
      }
    },
    PODCAST: {
      path: "claim"
    }
  },
  COMPANIES: {
    path: "companies",
    DOMAINS: {
      path: "companies/domains"
    }
  },
  PROGRAMS: {
    path: "programs"
  },
  CONFIGURATIONS: {
    path: "configurations",
    DOMAIN: {
      path: "configurations"
    },
    PODCAST: {
      path: "configurations"
    },
    CATEGORY: {
      path: "configurations/category"
    },
    CATEGORIES: {
      path: "configurations/categories"
    },
    CATEGORIES_TYPES: {
      path: "configurations/categories/types"
    }
  },
  USERS: {
    path: "users",
    SESSION: {
      path: "session"
    }
  },
  FILTERS: {
    PROGRAMS: {
      path: "programs"
    },
    STATS: {
      path: "podcasts/stats",
      value: "terms"
    }
  },
  EXPORT: {
    PROGRAMS: {
      path: "programs",
      name: "export"
    },
    COUNTRIES: {
      path: "countries"
    }
  },
  AUDIENCE_HEATMAP: {
    path: "stats/dashboard",
    name: "heatmap",
    value: "publisher"
  },
  SPLIT_PROGRAMS: {
    path: "stats/dashboard",
    name: "program",
    value: "global"
  },
  SPLIT_DEVICES: {
    path: "stats/dashboard",
    name: "device",
    value: "global"
  },
  RANKING_EPISODES: {
    path: "stats/dashboard",
    name: "episode",
    value: "global"
  },
  DOMAINS: {
    path: "configurations",
    name: "domains"
  },
  PODCASTS: {
    path: "podcasts",
    RESET_ATTEMPT: {
      path: "resetattempt"
    },
    FORCE_PARSING: {
      path: "forceparsing"
    },
    FORCE_EXECUTING_NOW: {
      path: "force_import"
    },
    EPISODES: {
      path: "episodes"
    },
    CLEANER_IMAGES: {
      path: "images/cleaner"
    }
  }
};
